const state = {
  showDrawer: true,
  showLoadingOverlay: false,
};

const mutations = {
  SET_DRAWER: (state, status) => {
    state.showDrawer = status;
  },
  SET_LOADINGOVERLAY: (state, status) => {
    state.showLoadingOverlay = status;
  },
};

const actions = {
  setDrawer({ commit }, status) {
    commit("SET_DRAWER", status);
  },
  setLoadingOverlay({ commit }, status) {
    commit("SET_LOADINGOVERLAY", status);
  },
};

const getters = {
  showDrawer: (state) => state.showDrawer,
  showLoadingOverlay: (state) => state.showLoadingOverlay,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
