import axios from "axios";
import store from "@/store";
import AuthenticationADAL from "@/plugins/AuthenticationADAL";
import Swal from "sweetalert2";

axios.interceptors.request.use(async (config) => {
  let token = "";
  try {
    token = await AuthenticationADAL.acquireToken();
  } catch (err) {
    console.error(err);
    Swal.fire({
      title: "您的驗證已過期，請重新登入",
      icon: "warning",
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      if (result.isConfirmed) {
        store.dispatch("user/logout");
      }
    });
    // token = `ThisIsForPOC-20210809:user_test_creator`;
    // config.headers["X-Authorization-mode"] = `AUTHORIZATION_MODE_SECRET_KEY`;
  } finally {
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  }
});

export default axios;
