import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/containers/TheLayout"),
    children: [
      {
        path: "/",
        component: () => import("@/views/Index"),
        name: "Index",
        meta: {
          headerTitle: "首頁",
        },
      },
      {
        path: "/create",
        component: () => import("@/views/Create"),
        name: "Create",
        meta: {
          specificSource: true,
        },
      },
      {
        path: "/ticket",
        component: () => import("@/views/Ticket"),
        name: "Ticket",
        meta: {
          loginRequired: true,
        },
      },
    ],
  },
  {
    path: "/adal/login",
    component: () => import("@/views/pages/ADALLogin"),
  },
  {
    path: "*",
    component: () => import("@/views/pages/404"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.loginRequired && !store.getters["user/userData"].access_token)
    next({ name: "Index" });
  else next();
});

export default router;
