/**
 * 系統使用者
 */
class ADAL {
  /**
   * 登入
   */
  login(token) {
    return axios.get(process.env.VUE_APP_AUTH_API_URL + `/auth/login`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

var adal = new ADAL();
export default adal;
