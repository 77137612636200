import AuthenticationADAL from "@/plugins/AuthenticationADAL";
import ADAL from "@/api/ADAL";
import jwtDecode from "jwt-decode";
import settings from "@/settings";
import axios from "axios";

const state = {
  userData: {},
  roles: [],
};

const mutations = {
  SET_USERDATA: (state, userData) => {
    state.userData = userData;
    axios.defaults.headers.common["Authorization"] =
      userData && userData.access_token ? userData.access_token : null;
    localStorage.setItem("user", JSON.stringify(userData || {}));
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
    localStorage.setItem("roles", roles);
  },
};

const actions = {
  async getADALData({ state, commit }) {
    const userData = {};
    if (AuthenticationADAL.isAuthenticated()) {
      if (!state.userData.access_token || !state.userData.name) {
        try {
          const token = await AuthenticationADAL.acquireToken();
          userData.access_token = token;
          const userName = await AuthenticationADAL.getUserName();
          userData.name = userName;
          commit("SET_USERDATA", userData);
        } catch (err) {
          console.error(err);
        }
      }
    }
    console.log(userData);
    return userData;
  },
  async setADALData({ commit }, userData) {
    commit("SET_USERDATA", userData);
  },
  // user logout
  async logout({ commit }) {
    commit("SET_USERDATA", null);
    AuthenticationADAL.signOut();
  },
  async getInfo({ commit, state }) {
    const userData = JSON.parse(localStorage.getItem("user") || "{}");
    if (userData && userData !== state && userData.access_token) {
      commit("SET_USERDATA", userData);
    }
    return userData;
  },
  setRoles({ commit }, roles) {
    commit("SET_ROLES", roles);
  },
};
const getters = {
  userData: (state) => state.userData,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
