import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css"; // Icon
import "./vendor"; // 其他套件
import AuthenticationADAL from "@/plugins/AuthenticationADAL";

Vue.config.productionTip = false;

AuthenticationADAL.initialize().then((_) => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
