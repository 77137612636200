import Vue from "vue";
import Vuetify from "vuetify/lib";

import customTheme from "./customTheme";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: customTheme,
    },
  },
});

export default vuetify;
