import Vue from "vue";

// axios
import axios from "./axios";
window.axios = axios;

// Sweetalert2
import Swal from "sweetalert2";
window.Swal = Swal;

import moment from "moment";
window.moment = moment;
Vue.prototype.moment = moment;
